import en from "@/locales/en.json";
import HKPHILLogo from "@public/logos/hkphil.jpg";
import MAPLogo from "@public/logos/map.jpg";
import NGSLogo from "@public/logos/ngs.svg";
import SCMPLogo from "@public/logos/scmp.png";
import SNMLogo from "@public/logos/snm.png";
import SZABOTAGELogo from "@public/logos/szabotage.jpg";
import TitanicLogo from "@public/logos/titanic.svg";
import USNLogo from "@public/logos/usn.png";

// TODO: get the config from server side
// TODO: integrate i18n when the requirement comes
export const getLocalizationProvider = (tenantId: string = ""): any => {
  if (tenantId.includes("ngs")) {
    return {
      ...en.translation.general,
      searchBar: {
        placeholder:
          "Discover the world of modern and contemporary art of Southeast Asia through the National Gallery Singapore's collection!",
      },
    };
  } else if (tenantId.includes("usn")) {
    return {
      ...en.translation.general,
      searchBar: {
        placeholder: "Discover the world of modern and contemporary art!",
      },
    };
  } else if (tenantId.includes("platform")) {
    return {
      ...en.translation.general,
      searchBar: {
        placeholder: "Discover the world of modern and contemporary collection!",
      },
    };
  } else if (tenantId.includes("snm")) {
    return {
      ...en.translation.general,
      ...en.translation.snm,
    };
  } else if (tenantId.includes("hkphil")) {
    return {
      ...en.translation.general,
    };
  } else if (tenantId.includes("map")) {
    return {
      ...en.translation.general,
      facet: {
        ...en.translation.general.facet,
        assetTypeFacet: "Medium",
      },
      metadata: {
        ...en.translation.general.metadata,
        assetType: "Medium",
      },
    };
  } else if (tenantId.includes("szabotage")) {
    return {
      ...en.translation.general,
    };
  } else {
    return {
      ...en.translation.general,
    };
  }
};

export const getSettingsByTenantId = (tenantId: string) => {
  if (tenantId.includes("general")) {
    return {
      withLogo: false,
      width: 48,
    };
  } else if (tenantId.includes("ngs")) {
    return {
      withLogo: true,
      width: 120,
      iconPath: NGSLogo,
    };
  } else if (tenantId.includes("titanic")) {
    return {
      withLogo: true,
      width: 106,
      iconPath: TitanicLogo,
    };
  } else if (tenantId.includes("scmp")) {
    return {
      withLogo: true,
      width: 280,
      iconPath: SCMPLogo,
    };
  } else if (tenantId.includes("usn")) {
    return {
      withLogo: true,
      width: 42,
      iconPath: USNLogo,
    };
  } else if (tenantId.includes("platform")) {
    return {
      withLogo: false,
    };
  } else if (tenantId.includes("snm")) {
    return {
      withLogo: true,
      width: 42,
      iconPath: SNMLogo,
    };
  } else if (tenantId.includes("hkphil")) {
    return {
      withLogo: true,
      width: 64,
      iconPath: HKPHILLogo,
    };
  } else if (tenantId.includes("map")) {
    return {
      withLogo: true,
      width: 80,
      iconPath: MAPLogo,
    };
  } else if (tenantId.includes("szabotage")) {
    return {
      withLogo: true,
      width: 80,
      iconPath: SZABOTAGELogo,
    };
  } else {
    return {
      withLogo: false,
    };
  }
};

export const getTenantConfigs = (tenantId: string) => {
  return {
    settings: getSettingsByTenantId(tenantId),
    translation: getLocalizationProvider(tenantId),
  };
};
